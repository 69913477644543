import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as dayjs from 'dayjs'
import PropTypes from 'prop-types'

const CustomDatePicker = ({
  fullWidth,
  label,
  name,
  value,
  onChange = () => {},
  clearable = false,
  disabled = false,
  variant,
  helperText,
  sx = {},
  error,
  minDate = null,
  maxDate = null,
  onBlur = () => {},
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disabled={disabled}
        name={name}
        value={dayjs(value)}
        onChange={onChange}
        onClose={onBlur} // validate on date picker close
        minDate={minDate}
        maxDate={maxDate}
        slotProps={{
          field: { clearable: clearable && value, onBlur }, // validate on blur
          textField: {
            sx: {
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.23) !important',
                  color: error ? 'red' : 'rgba(0, 0, 0, 0.23) !important',
                },
                '&:hover fieldset': {
                  borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.87) !important',
                  color: error ? 'red' : 'rgba(0, 0, 0, 1) !important',
                },
                '&.Mui-focused fieldset': {
                  borderColor: error ? 'red' : '#1976d2',
                  color: error ? 'red' : '#1976d2',
                },
              },
              '& .MuiFormLabel-root': {
                color: error ? 'red' : 'rgba(0, 0, 0, 1) !important',
                '&.Mui-focused': {
                  color: error ? 'red' : '#1976d2',
                },
              },
              ...sx,
            },

            helperText,
            error,
            color: error ? 'error' : 'black',
            size: 'small',
            variant,
            label,
            fullWidth,
          },
        }}
      />
    </LocalizationProvider>
  )
}
CustomDatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  error: PropTypes.bool,
}
export { CustomDatePicker }
