import { SUPPORT_TABLE_FORM, CREATE_INVENTORY, ADVANCED_SEARCH } from '../../constants/ModalNames'

import { SupportTableForm } from '../../modules/SupportTable/SupportTableForm'
import { AddNewInventoryModal } from '../../modules/Inventory/Components/AddNewInventoryModal'
import { Filters as InventoryFilters } from '../../modules/Inventory/Components/Filters'
const modalsArray = []

modalsArray[SUPPORT_TABLE_FORM] = {
  Component: SupportTableForm,
  size: 'sm',
  modalTop: 35,
  modalLeft: 30,
}
modalsArray[CREATE_INVENTORY] = {
  Component: AddNewInventoryModal,
  size: 'lg',
  modalTop: 30,
  modalLeft: 30,
}

modalsArray[ADVANCED_SEARCH] = {
  Component: InventoryFilters,
  size: 'lg',
  modalTop: 30,
  modalLeft: 30,
}

export const getModal = (id) => {
  return modalsArray[id]
}
