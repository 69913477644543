import { Button, Grid2 as Grid } from '@mui/material'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { ControlledDateRangePicker, ControlledInput, ControlledNumberRangePicker, ControlledSelect, ControlledSwitch } from '../../../common/Inputs'

import { ControlledAsyncSelect } from '../../../common/Inputs/ControlledInputs/ControlledAsyncSelect'
import { INVENTORY_SUPPORT_TYPES } from '../../../constants/SupportTypes'
import { useAsyncSelect } from '../../../hooks/useAsyncSelect'
import { useInputHandlers } from '../../../hooks/useInputHandlers'
import { useSupportTableOptions } from '../../SupportTable/supportTableApiHooks'

const BASIC_FILTERS = [
  'vin',
  'stockNumber',
  'keyNumber',
  'yearId',
  'makeId',
  'modelId',
  'colorId',
  'mileage',
  'engineManufacturerId',
  'transmissionId',
  'dateIn',
  'status',
  'salespersonId',
  'retailPrice',
  'locationId',
]

export const Filters = ({ onSearch, isBasicFilters = false }) => {
  const { getValues, control, setValue } = useForm({
    defaultValues: {
      horsepower: {
        from: null,
        to: null,
      },
      colorId: null,
      yearId: [],
      makeId: [],
      engineModelId: [],
      purchaseDate: {
        from: null,
        to: null,
      },
      wholesalePrice: {
        from: null,
        to: null,
      },
      salespersonId: [],
      stockNumber: null,
      locationId: [],
      mileage: {
        from: null,
        to: null,
      },
      salesPrice: {
        from: null,
        to: null,
      },
      transportationPrice: {
        from: null,
        to: null,
      },
      keyNumber: null,
      vendorId: [],
      modelId: [],
      status: [],
      retailPrice: {
        from: null,
        to: null,
      },
      profit: {
        from: null,
        to: null,
      },
      repairCost: {
        from: null,
        to: null,
      },
      ctsTitleOptionsId: [],
      dateIn: {
        from: null,
        to: null,
      },
      'inventories.creation_date': {
        from: null,
        to: null,
      },
      engineManufacturerId: [],
      engineBreak: false,
      apu: false,
      ecmMiles: {
        from: null,
        to: null,
      },
      transmissionId: [],
      transmissionManufacturerId: [],
      transmissionModelId: [],
      tiresId: [],
      rearAxleWeight: {
        from: null,
        to: null,
      },
      grossVehicleWeightRatingId: [],
      wheelsId: [],
      sleeperId: [],
      sleeperSizeId: [],
      numberOfBeds: null,
      purchasePrice: {
        from: null,
        to: null,
      },
    },
  })

  const { fetchOptions: fetchVinOptions } = useAsyncSelect('/inventories/options/vin')

  const { data: options = {} } = useSupportTableOptions(INVENTORY_SUPPORT_TYPES)
  const { onChange, onDateChange, onSelectChange } = useInputHandlers(setValue)

  const onSubmit = () => {
    const values = getValues()
    onSearch(values)
  }

  const shouldShowFilter = useCallback(
    (filterName) => {
      if (!isBasicFilters) return true
      return BASIC_FILTERS.includes(filterName)
    },
    [isBasicFilters]
  )

  const memoizedRenderSwitch = useCallback(
    ({ name, label, size = { xs: 12, sm: 12, md: 3 } }) => (
      <Grid size={size}>
        <ControlledSwitch fullWidth control={control} label={label} name={name} />
      </Grid>
    ),
    [control]
  )

  const memoizedRenderSelect = useCallback(
    ({ name, label, options: optionsKey, size = { xs: 12, sm: 12, md: 3 } }) => (
      <Grid size={size}>
        <ControlledSelect
          fullWidth
          multiple
          control={control}
          label={label}
          name={name}
          options={options[optionsKey] || []}
          variant='outlined'
          onChange={(e, value) => onSelectChange(e, value, name, true)}
        />
      </Grid>
    ),
    [control, onSelectChange, options]
  )

  const memoizedRenderNumberRangePicker = useCallback(
    ({ name, label, size = { xs: 12, sm: 12, md: 3 } }) => (
      <Grid size={size}>
        <ControlledNumberRangePicker fullWidth control={control} label={label} name={name} min={0} onChange={onChange} />
      </Grid>
    ),
    [control, onChange]
  )

  const memoizedRenderInput = useCallback(
    ({ name, label, type = 'text', size = { xs: 12, sm: 12, md: 3 } }) => (
      <Grid size={size}>
        <ControlledInput fullWidth control={control} label={label} name={name} type={type} min={0} onChange={onChange} />
      </Grid>
    ),
    [control, onChange]
  )

  const memoizedRenderDateRangePicker = useCallback(
    ({ name, label, size = { xs: 12, sm: 12, md: 3 } }) => (
      <Grid size={size}>
        <ControlledDateRangePicker
          fullWidth
          control={control}
          label={label}
          name={name}
          variant='outlined'
          onChange={(value) => onDateChange(value, name)}
        />
      </Grid>
    ),
    [control, onDateChange]
  )

  return (
    <div>
      <Grid container spacing={2}>
        {shouldShowFilter('vin') && (
          <Grid size={{ xs: 12, sm: 12, md: 3 }}>
            <ControlledAsyncSelect multiple control={control} name='vin' label='VIN' fetchOptions={fetchVinOptions} onChange={onSelectChange} />
          </Grid>
        )}

        {shouldShowFilter('yearId') &&
          memoizedRenderSelect({
            name: 'yearId',
            label: 'Year',
            options: 'Years',
          })}

        {shouldShowFilter('horsepower') &&
          memoizedRenderNumberRangePicker({
            name: 'horsepower',
            label: 'Horsepower',
          })}

        {shouldShowFilter('engineModelId') &&
          memoizedRenderSelect({
            name: 'engineModelId',
            label: 'Engine',
            options: 'Engines',
          })}

        {shouldShowFilter('engineManufacturerId') &&
          memoizedRenderSelect({
            name: 'engineManufacturerId',
            label: 'Engine Manufacturer',
            options: 'EnginesManufactures',
          })}

        {shouldShowFilter('apu') &&
          memoizedRenderSwitch({
            name: 'apu',
            label: 'APU',
          })}

        {shouldShowFilter('engineBreak') &&
          memoizedRenderSwitch({
            name: 'engineBreak',
            label: 'Engine Break',
          })}

        {shouldShowFilter('rearAxleWeight') &&
          memoizedRenderNumberRangePicker({
            name: 'rearAxleWeight',
            label: 'Rear Axle Weight',
          })}

        {shouldShowFilter('colorId') &&
          memoizedRenderSelect({
            name: 'colorId',
            label: 'Color',
            options: 'Colors',
          })}

        {shouldShowFilter('modelId') &&
          memoizedRenderSelect({
            name: 'modelId',
            label: 'Model',
            options: 'Models',
          })}

        {shouldShowFilter('status') &&
          memoizedRenderSelect({
            name: 'status',
            label: 'Status',
            options: 'Status',
          })}

        {shouldShowFilter('transmissionId') &&
          memoizedRenderSelect({
            name: 'transmissionId',
            label: 'Transmission',
            options: 'Transmissions',
          })}

        {shouldShowFilter('transmissionManufacturerId') &&
          memoizedRenderSelect({
            name: 'transmissionManufacturerId',
            label: 'Transmission Manufacturer',
            options: 'TransmissionManufacturers',
          })}

        {shouldShowFilter('transmissionModelId') &&
          memoizedRenderSelect({
            name: 'transmissionModelId',
            label: 'Transmission Model',
            options: 'TransmissionModels',
          })}

        {shouldShowFilter('tiresId') &&
          memoizedRenderSelect({
            name: 'tiresId',
            label: 'Tires',
            options: 'Tires',
          })}

        {shouldShowFilter('wheelsId') &&
          memoizedRenderSelect({
            name: 'wheelsId',
            label: 'Wheels',
            options: 'Wheels',
          })}

        {shouldShowFilter('grossVehicleWeightRatingId') &&
          memoizedRenderSelect({
            name: 'grossVehicleWeightRatingId',
            label: 'GVWR',
            options: 'GrossVehicleWeightRatings',
          })}

        {shouldShowFilter('rearAxleWeight') &&
          memoizedRenderNumberRangePicker({
            name: 'rearAxleWeight',
            label: 'Rear Axle Weight',
          })}

        {shouldShowFilter('dateIn') &&
          memoizedRenderDateRangePicker({
            name: 'dateIn',
            label: 'Date In',
          })}

        {shouldShowFilter('inventories.creation_date') &&
          memoizedRenderDateRangePicker({
            name: 'inventories.creation_date',
            label: 'Creation Date',
          })}

        {shouldShowFilter('purchaseDate') &&
          memoizedRenderDateRangePicker({
            name: 'purchaseDate',
            label: 'Purchase Date',
          })}

        {shouldShowFilter('retailPrice') &&
          memoizedRenderNumberRangePicker({
            name: 'retailPrice',
            label: 'Retail Price',
          })}

        {shouldShowFilter('previousOwnerId') &&
          memoizedRenderSelect({
            name: 'previousOwnerId',
            label: 'Previous Owner',
            options: 'PreviousOwners',
          })}

        {shouldShowFilter('salespersonId') &&
          memoizedRenderSelect({
            name: 'salespersonId',
            label: 'Salesperson',
            options: 'salespersons',
          })}

        {shouldShowFilter('stockNumber') &&
          memoizedRenderInput({
            name: 'stockNumber',
            label: 'Stock Number',
            type: 'number',
          })}

        {shouldShowFilter('makeId') &&
          memoizedRenderSelect({
            name: 'makeId',
            label: 'Manufacturer',
            options: 'Makes',
          })}

        {shouldShowFilter('locationId') &&
          memoizedRenderSelect({
            name: 'locationId',
            label: 'Location',
            options: 'Locations',
          })}

        {shouldShowFilter('wholesalePrice') &&
          memoizedRenderNumberRangePicker({
            name: 'wholesalePrice',
            label: 'Wholesale Price',
          })}

        {shouldShowFilter('profit') &&
          memoizedRenderNumberRangePicker({
            name: 'profit',
            label: 'Profit',
          })}

        {shouldShowFilter('repairCost') &&
          memoizedRenderNumberRangePicker({
            name: 'repairCost',
            label: 'Repair Cost',
          })}

        {/*  */}

        {shouldShowFilter('mileage') &&
          memoizedRenderNumberRangePicker({
            name: 'mileage',
            label: 'Mileage',
          })}

        {shouldShowFilter('ecmMiles') &&
          memoizedRenderNumberRangePicker({
            name: 'ecmMiles',
            label: 'EC Miles',
          })}

        {shouldShowFilter('salesPrice') &&
          memoizedRenderNumberRangePicker({
            name: 'salesPrice',
            label: 'Sales Price',
          })}

        {shouldShowFilter('transportationPrice') &&
          memoizedRenderNumberRangePicker({
            name: 'transportationPrice',
            label: 'Transportation Cost',
          })}

        {shouldShowFilter('keyNumber') &&
          memoizedRenderInput({
            name: 'keyNumber',
            label: 'Key Number',
            type: 'number',
          })}

        {shouldShowFilter('vendorId') &&
          memoizedRenderSelect({
            name: 'vendorId',
            label: 'Vendor',
            options: 'Vendors',
          })}

        {shouldShowFilter('modelId') &&
          memoizedRenderSelect({
            name: 'modelId',
            label: 'Model',
            options: 'Models',
          })}

        {shouldShowFilter('status') &&
          memoizedRenderSelect({
            name: 'status',
            label: 'Status',
            options: 'Status',
          })}

        {shouldShowFilter('sleeperId') &&
          memoizedRenderSelect({
            name: 'sleeperId',
            label: 'Sleeper',
            options: 'Sleepers',
          })}

        {shouldShowFilter('sleeperSizeId') &&
          memoizedRenderSelect({
            name: 'sleeperSizeId',
            label: 'Sleeper Size',
            options: 'SleeperSize',
          })}

        {shouldShowFilter('numberOfBeds') &&
          memoizedRenderInput({
            name: 'numberOfBeds',
            label: 'Bed #',
            type: 'number',
          })}

        {shouldShowFilter('purchasePrice') &&
          memoizedRenderNumberRangePicker({
            name: 'purchasePrice',
            label: 'Purchase Price',
          })}

        {shouldShowFilter('purchasePrice') &&
          memoizedRenderNumberRangePicker({
            name: 'purchasePrice',
            label: 'Purchase Price',
          })}

        {shouldShowFilter('profit') &&
          memoizedRenderNumberRangePicker({
            name: 'profit',
            label: 'Profit',
          })}

        {shouldShowFilter('repairCost') &&
          memoizedRenderNumberRangePicker({
            name: 'repairCost',
            label: 'Repair Cost',
          })}

        {shouldShowFilter('ctsTitleOptionsId') &&
          memoizedRenderSelect({
            name: 'ctsTitleOptionsId',
            label: 'CTS Title Options',
            options: 'CtsTitleOptions',
          })}

        {shouldShowFilter('retailPrice') &&
          memoizedRenderNumberRangePicker({
            name: 'retailPrice',
            label: 'Retail Price',
          })}
      </Grid>

      <Grid sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button color='secondary' variant='contained' onClick={onSubmit}>
          Search
        </Button>
      </Grid>
    </div>
  )
}
