import axios from 'axios'
import { toastNotification, toastType } from './toast'

export const setAxiosInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (err) => {
      if (err.response.status >= 500) {
        toastNotification({ message: 'Server Error! Something went wrong.', type: toastType.error })
      }
      return new Promise((_, reject) => {
        reject(err)
      })
    }
  )
}
