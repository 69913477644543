export async function readBlobAsArrayBuffer(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsArrayBuffer(blob)
  })
}

export function extractAndCreateImageUrls(arrayBuffer) {
  const dataView = new DataView(arrayBuffer)
  const urls = []
  let offset = 0

  while (offset < dataView.byteLength) {
    if (isStartOfImage(dataView, offset)) {
      const imageEndOffset = findEndOfImage(dataView, offset)
      if (imageEndOffset !== -1) {
        const imageBlob = new Blob([arrayBuffer.slice(offset, imageEndOffset)], { type: 'image/png' })
        urls.push(URL.createObjectURL(imageBlob))
        offset = imageEndOffset
      }
    } else {
      offset++
    }
  }

  return urls
}

function isStartOfImage(dataView, offset) {
  return (
    (dataView.getUint8(offset) === 0xff && dataView.getUint8(offset + 1) === 0xd8) || // JPG
    (dataView.getUint8(offset) === 0x89 &&
      dataView.getUint8(offset + 1) === 0x50 && // PNG
      dataView.getUint8(offset + 2) === 0x4e &&
      dataView.getUint8(offset + 3) === 0x47)
  )
}

function findEndOfImage(dataView, offset) {
  if (dataView.getUint8(offset) === 0xff && dataView.getUint8(offset + 1) === 0xd8) {
    // JPG
    while (offset < dataView.byteLength) {
      if (dataView.getUint8(offset) === 0xff && dataView.getUint8(offset + 1) === 0xd9) {
        return offset + 2
      }
      offset++
    }
  } else if (
    dataView.getUint8(offset) === 0x89 &&
    dataView.getUint8(offset + 1) === 0x50 && // PNG
    dataView.getUint8(offset + 2) === 0x4e &&
    dataView.getUint8(offset + 3) === 0x47
  ) {
    while (offset < dataView.byteLength) {
      if (
        dataView.getUint8(offset - 4) === 0x49 &&
        dataView.getUint8(offset - 3) === 0x45 &&
        dataView.getUint8(offset - 2) === 0x4e &&
        dataView.getUint8(offset - 1) === 0x44
      ) {
        return offset
      }
      offset++
    }
  }

  return -1
}
