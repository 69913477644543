import * as yup from 'yup'

yup.addMethod(yup.string, 'vinValidation', function (errorMessage) {
  return this.test('test-vin-validation', errorMessage, function (value) {
    return vinValidation(value)
  })
})

export const inventorySchema = yup.object({
  vin: yup.string().vinValidation('VIN must be valid!').required('VIN is required'),
  previousOwnerId: yup.number().nullable().required('Previous Owner is required'),
  vendorId: yup.number().nullable().required('Vendor is required'),
  yearId: yup.number().nullable().required('Year is required'),
  makeId: yup.number().nullable().required('Make is required'),
  modelId: yup.number().nullable().required('Model is required'),
  purchasePrice: yup
    .number()
    .nullable()
    .required('Purchase Price is required')
    .typeError('Purchase Price Must Be A Number')
    .positive('Purchase Price Must Be Positive'),
  purchaseDate: yup.date().nullable().required('Purchase Date is required').typeError('Purchase Date is invalid'),
})

const vinValidation = (value) => {
  if (!value) return false
  if (value.length !== 17) return false

  let sum = 0
  const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2]
  const atov = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    F: 6,
    G: 7,
    H: 8,
    J: 1,
    K: 2,
    L: 3,
    M: 4,
    N: 5,
    P: 7,
    R: 9,
    S: 2,
    T: 3,
    U: 4,
    V: 5,
    W: 6,
    X: 7,
    Y: 8,
    Z: 9,
  }

  for (let i = 0; i < 10; i++) atov['' + i] = i
  for (let i = 0; i < 17; i++) {
    var val = atov[value.charAt(i)]
    if (val === undefined) return false
    sum += val * weights[i]
  }

  let remainder = '' + (sum % 11)
  if (remainder == '10') remainder = 'X'

  const checkDigit = value.charAt(8)
  return remainder == checkDigit
}

export const validateVinValue = (value) => {
  console.log('value!s', value)
  if (!value) return '* Required'
  if (!vinValidation(value)) {
    return '* VIN is not valid'
  }

  return true
}
