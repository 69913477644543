import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'
import React from 'react'

const CustomSelect = ({
  multiple = false,
  label,
  onChange,
  value,
  name,
  disabled = false,
  disableCloseOnSelect = false,
  options = [],
  variant,
  helperText,
  sx = {},
  error,
  onBlur = () => {},
}) => {
  const getSelectedValue = React.useCallback(() => {
    if (!options) {
      return []
    }

    // TODO: handle grouped options
    // if (isGrouped) {
    //   let flattenOptions = options.reduce((acc, curVal) => {
    //     return acc.concat(curVal.options)
    //   }, [])

    return multiple
      ? options.filter((option) => {
          return value.includes(option.id) || value.includes(option.value)
        })
      : options.find((option) => option.value === value || option.id === value) || 0
    // }
  }, [multiple, value, options])

  return (
    <Autocomplete
      multiple={multiple}
      size='small'
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      getOptionLabel={(option) => {
        return option.name || ''
      }}
      sx={sx}
      value={getSelectedValue()}
      filterSelectedOptions
      disableCloseOnSelect={disableCloseOnSelect}
      ChipProps={{ size: 'small' }}
      disabled={disabled}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          slotProps={{
            inputLabel: {
              color: 'label',
            },
            formHelperText: {
              color: error ? 'error' : 'primary',
            },
          }}
          helperText={helperText || ''}
          error={error}
          label={label}
          size='small'
          variant={variant}
        />
      )}
    />
  )
}

CustomSelect.propTypes = {
  multiple: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  variant: PropTypes.string,
}

export { CustomSelect }
