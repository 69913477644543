import React from 'react'
import { Controller } from 'react-hook-form'
import { CustomTooltip } from '../../Tooltip/CustomTooltip'
import { DateRangePicker } from '../DateRangePicker'
import PropTypes from 'prop-types'

export const ControlledDateRangePicker = ({ control, name, label, onChange }) => {
  return (
    <CustomTooltip title={label?.length > 10 ? label : ''} placement='top' arrow>
      <div>
        <Controller control={control} name={name} render={({ field }) => <DateRangePicker {...field} label={label} onChange={onChange} />} />
      </div>
    </CustomTooltip>
  )
}

ControlledDateRangePicker.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}
