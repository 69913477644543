import axios from 'axios'

export const useAsyncSelect = (url) => {
  const fetchOptions = async (options) => {
    const response = await axios.get(url, { params: { options } })
    return response.data
  }

  return {
    fetchOptions,
  }
}
