import axios from 'axios'
import { toastNotification, toastType } from './toast'

export const getFiles = async (url, keys) => {
  try {
    const config = {
      responseType: 'blob',
    }
    const response = await axios.post(
      url,
      {
        keys,
      },
      config
    )

    const contentType = response.headers['content-type'] ?? response.headers['Content-Type']
    const isZip = contentType?.includes('zip')
    const fileName = isZip ? 'documents.zip' : keys.split('/').pop()

    if (!response.data || response.data.size === 0) {
      toastNotification({ message: 'File is empty or corrupted.', type: toastType.error })
      return
    }

    const blob = new Blob([response.data], { type: contentType })
    const tempUrl = window.URL.createObjectURL(blob)

    // For PDFs and images, open in new tab. For other files, download
    if (contentType?.includes('pdf') || contentType?.includes('image')) {
      window.open(tempUrl, '_blank')
    } else {
      const link = document.createElement('a')
      link.href = tempUrl
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }

    // Cleanup
    window.URL.revokeObjectURL(tempUrl)
  } catch (error) {
    toastNotification({ message: 'File does not exist.', type: toastType.error })
  }
}
