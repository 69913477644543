import { createTheme } from '@mui/material/styles'

export const theme = (mode) => {
  const isDarkMode = mode === 'dark'

  const theme = createTheme({
    mode,
    palette: {
      mode,
      label: {
        main: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0,0,0, 1)',
      },
      primary: {
        main: '#c1121f',
        light: '#fc2334',
        dark: '#3D0000',
      },
      dark: {
        main: '#5A6268',
        light: '#23272B',
        dark: '#2f353a',
      },
      modal: {
        main: '#c1121f',
        dark: '#404040',
        light: '#eaeff1',
      },
      appBar: {
        main: '#c1121f',
        dark: '#2f353a',
        sidebar: '#eaeff1',
        footer: isDarkMode ? '#000' : '#eaeff1',
        user: isDarkMode ? '#eaeff1' : '#010c2c',
      },
      background: {
        paper: isDarkMode ? '#19282F' : '#FFF',
      },
    },
    typography: {
      fontFamily: '"Montserrat", sans-serif',
    },
    options: {
      appBar: {
        height: '4.1rem',
      },
      modal: {
        zIndex: 1150,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '*': {
            margin: 0,
            padding: 0,
            boxSizing: 'inherit',
            '&::before': {
              margin: 0,
              padding: 0,
              boxSizing: 'inherit',
            },
            '&::after': {
              margin: 0,
              padding: 0,
              boxSizing: 'inherit',
            },
          },
          html: {
            boxSizing: 'border-box',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            color: '#fff',
          },
        },
        defaultProps: {
          // The props to change the default for.
          variant: 'contained',
          size: 'small',
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: isDarkMode ? '#424242' : '#eaeff1',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            border: '2px solid grey',
          },

          'Mui-active': {
            styleOverrides: {
              root: {
                color: 'white',
              },
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? 'rgba(215, 168, 110, .6)' : 'rgba(171, 109, 35, .8)',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'center',
          },
          head: {
            fontWeight: 600,
            borderRight: mode === 'dark' ? '1px solid #575757' : '1px solid #dfdfdf',
            color: '#fff',
          },
          body: {
            borderRight: mode === 'dark' ? '1px solid #575757' : '1px solid #dfdfdf',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          sizeSmall: {
            fontSize: '14px',
          },
          filled: {
            fontSize: '14px !important',
          },
          root: {
            color: isDarkMode ? 'rgba(255,255,255, .8)' : 'rgba(0,0,0,.8)',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          inputSizeSmall: {
            fontSize: '14px',
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: '#fff !important',
          },
          icon: {
            width: '14px',
            height: '14px',
            margin: '0px',
            fill: '#fff',
          },
        },
      },
    },
  })

  return theme
}
