export const ENGINES = 1
export const ENGINES_MANUFACTURES = 2
export const TRANSMISSIONS = 3
export const COLORS = 4
export const MAKES = 5
export const MODELS = 6
export const VENDORS = 7
export const WARRANTY = 8
export const REPAIR_VENDORS = 9
export const CONDITIONS = 10
export const SLEEPER_SIZE = 11
export const WHEELS = 12
export const NUMBER_OF_REAR_AXLES = 13
export const LOCATIONS = 14
export const PREVIOUS_OWNERS = 15
export const YEARS = 16
export const ENGINE_MODELS = 17
export const TRANSMISSION_MANUFACTURES = 18
export const TRANSMISSION_MODELS = 19
export const DRIVERS = 20
export const SUSPENSIONS = 21
export const TIRES = 22
export const GROSS_VEHICLE_WIGHT_RATINGS = 23
export const SLEEPERS = 24
export const CTS_TITLE_OPTIONS = 25
export const STATUS = 26

export const INVENTORY_SUPPORT_TYPES = [
  ENGINES,
  ENGINES_MANUFACTURES,
  TRANSMISSIONS,
  COLORS,
  MAKES,
  MODELS,
  VENDORS,
  WARRANTY,
  REPAIR_VENDORS,
  CONDITIONS,
  SLEEPER_SIZE,
  WHEELS,
  NUMBER_OF_REAR_AXLES,
  LOCATIONS,
  PREVIOUS_OWNERS,
  YEARS,
  ENGINE_MODELS,
  TRANSMISSION_MANUFACTURES,
  TRANSMISSION_MODELS,
  DRIVERS,
  SUSPENSIONS,
  TIRES,
  GROSS_VEHICLE_WIGHT_RATINGS,
  SLEEPERS,
  SLEEPER_SIZE,
  CTS_TITLE_OPTIONS,
  STATUS,
]
