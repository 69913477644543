import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

export const AsyncSelect = ({
  name,
  label,
  onChange,
  initialValue = [],
  fetchOptions,
  getOptionLabel = (option) => option?.name || '',
  isOptionEqualToValue = (option, value) => option?.id === value?.id,
  multiple = false,
}) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(initialValue)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  const loadOptions = async (currentPage) => {
    try {
      setLoading(true)
      const response = await fetchOptions({
        pagination: {
          page: currentPage,
          limit: 25,
        },
      })

      if (response?.data) {
        setOptions((prev) => (currentPage === 1 ? response.data : [...prev, ...response.data]))
        setHasMore(response.hasNext)
      }
    } catch (error) {
      console.error('Failed to fetch options:', error)
    } finally {
      setLoading(false)
    }
  }

  // Load initial options when opened
  useEffect(() => {
    if (open) {
      loadOptions(1)
    }
  }, [open])

  // Clear options when closed
  useEffect(() => {
    if (!open) {
      setPage(1)
      setHasMore(true)
    }
  }, [open])

  const handleChange = (event, newValue, reason) => {
    if (reason === 'clear') {
      const emptyValue = multiple ? [] : null
      setValue(emptyValue)
      onChange?.(event, emptyValue, name, multiple)
      return
    }

    setValue(newValue)
    onChange?.(event, newValue, name, multiple)
  }

  return (
    <Autocomplete
      name={name}
      multiple={multiple}
      open={open}
      onOpen={() => {
        setOpen(true)
        setPage(1)
      }}
      onClose={() => setOpen(false)}
      value={value}
      onChange={handleChange}
      clearOnBlur={false}
      clearOnEscape={false}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      size='small'
      options={options}
      loading={loading}
      ListboxProps={{
        onScroll: (event) => {
          const listboxNode = event.currentTarget
          if (!loading && hasMore && listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 50) {
            setPage((prev) => {
              const nextPage = prev + 1
              loadOptions(nextPage)
              return nextPage
            })
          }
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          slotProps={{
            input: {
              ...params.InputProps,

              endAdornment: <>{loading ? <CircularProgress color='inherit' size={20} /> : params.InputProps.endAdornment}</>,
            },
          }}
        />
      )}
      disableCloseOnSelect={multiple}
    />
  )
}
