import React from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { CustomDatePicker } from '../CustomDatePicker'

export const ControlledDatePicker = ({ name, control, validate, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState: { isSubmitted } }) => (
        <CustomDatePicker
          {...field}
          {...props}
          onBlur={() => {
            if (isSubmitted) {
              field.onBlur()
              if (typeof validate == 'function') {
                validate(name)
              }
            }
          }}
          name={name}
          value={field.value ?? ''}
          error={Boolean(error)}
          helperText={error ? error.message : props.helperText}
        />
      )}
    />
  )
}

ControlledDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
}
