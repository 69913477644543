import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { setAuthToken } from '../utils/setAuthToken'

const userState = {
  isAuth: false,
  user: {},
}

// export const loginUser = createAsyncThunk('user/loginUser', async (loginData) => {
//   const { data } = await axios.post('/users/login', loginData)
//   sessionStorage.setItem('TSToken', data.token)
//   setAuthToken(data.token)
//   const decoded = jwt_decode(data.token)

//   return decoded
// })

const UserSlice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {
    setCurrentUser: (state, { payload }) => {
      state.isAuth = payload.isAuth
      state.user = payload.user
    },
    logoutUser: (state) => {
      state.isAuth = false
      state.user = {}
      sessionStorage.setItem('TSToken', '')
    },
  },
  // extraReducers: {
  //   [loginUser.fulfilled]: (state, { payload }) => {
  //     return { isAuth: true, user: payload }
  //   },
  // },
})

export const { setCurrentUser, logoutUser } = UserSlice.actions

export default UserSlice.reducer
